import React, { useState, useEffect } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@material-ui/core";

//redux
import { connect } from "react-redux";
import { fetchLicenses } from "../../../../stores/licenses/actions";

function UserLicensesModal({
    open,
    // onConfirm = () => {},
    onClose = () => { },
    user,
    fetchLicenses,
    licenses
}) {
    useEffect(() => {
        if (user?._id) {
            fetchLicenses(user?._id)
        }
    }, [user])
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Licenses of {user.name}</DialogTitle>
            <DialogContent>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Website</TableCell>
                            <TableCell>License</TableCell>
                            <TableCell>Expiry</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {licenses.map(license => (
                            <TableRow key={license._id}>
                                <TableCell>{license.website}</TableCell>
                                <TableCell>{license.licenseKey}</TableCell>
                                <TableCell>{license.expiry}</TableCell>
                            </TableRow>)
                        )}
                        {licenses.length === 0 && (
                            <TableRow>
                                <TableCell>No licenses found!</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={onClose}>Cancel</Button>
                {/* <Button
            color="primary"
            onClick={() => { onConfirm() }}
          >
            Manage Sites
          </Button> */}
            </DialogActions>
        </Dialog>
    )
}
const mapStateToProps = (state) => ({
    licenses: state.licenses.licenses
});
const mapDispatchToProps = {
    fetchLicenses,
};
export default connect(mapStateToProps, mapDispatchToProps)(UserLicensesModal);