import React, { useState, useEffect } from "react";
import { Drawer, IconButton, List } from "@material-ui/core";
import {
  Home as HomeIcon,
  ArrowBack as ArrowBackIcon,
  LocalOffer,
  AccountCircle,
  // SettingsApplications,
  Web,
  People,
  CardMembership,
  Category,
} from "@material-ui/icons";
import {FaQrcode} from "react-icons/fa"
import { useTheme } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";

// styles
import useStyles from "./styles";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";

//redux
import { connect } from "react-redux";

const structureAdmin = [
  { id: 0, label: "Users", link: "/admin/users", icon: <People /> },
  { id: 1, label: "QR Codes", link: "/admin/qr-codes", icon: <FaQrcode size={20}/> },
  { id: 1, label: "Categories", link: "/admin/card-categories", icon: <Category /> },
  { id: 1, label: "Cards", link: "/admin/cards", icon: <CardMembership /> },
  { id: 3, label: "Profile", link: "/admin/profile", icon: <AccountCircle /> },
];

function Sidebar({ location, profile }) {
  var classes = useStyles();
  var theme = useTheme();

  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  // local
  var [isPermanent, setPermanent] = useState(true);

  useEffect(function() {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });
  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
        <List className={classes.sidebarList}>
        {structureAdmin.map(link => (
          <SidebarLink
            key={link.id}
            location={location}
            isSidebarOpened={isSidebarOpened}
            {...link}
          />
        ))}
      </List>
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}
const mapStateToProps = (state) => ({
  profile: state.profile.profile,
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Sidebar));