import React, { useState } from "react";
import {
    List,
    ListItem,
    ListItemText,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    useMediaQuery,
    useTheme,
    IconButton,
    Tooltip,
    Switch
} from "@material-ui/core";
import { ListRounded } from "@material-ui/icons"
import Swal from "sweetalert2"
import UserLicensesModal from "./UserLicensesModal";
import moment from "moment";
import Pagination from '../../../../components/Pagination/Pagination';

//redux
import { connect } from "react-redux";
import { updateUser, fetchUsers } from "../../../../stores/users/actions";

function UserTable({ users, updateUser, paginate, fetchUsers }) {
    const [openModal, setOpenModal] = useState(false)
    const [selectedUser, setSelectedUser] = useState({})
    const useStyles = makeStyles(() => ({
        root: {
            tableHead: {
                fontWeight: 700
            }
        }
    }))
    const theme = useTheme()
    const match = useMediaQuery(theme.breakpoints.up('sm'))
    const onUpdateUser = async (id, checked) => {
        Swal.fire({
            title: 'Are you sure want to change the user status?',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            icon: "warning"
        }).then(async (result) => {
            if (result.isConfirmed) {
                await updateUser({ activated: checked }, id)
            }
        })
    }
    const handleChangePage = async(event) =>{
        const page = event.selected
        await fetchUsers({
            limit: paginate.limit, 
            offset: paginate.offset * page
        })
    }
    const classes = useStyles()
    const returnObject = (
        <>
            {match
                ?
                (<Table>
                    <TableHead className={classes.tableHead}>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Miitap Link</TableCell>
                            <TableCell>Registered On</TableCell>
                            {/* <TableCell>Action</TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.map(user => (
                            <TableRow key={user._id}>
                                <TableCell>{user._id}</TableCell>
                                <TableCell>{`${user.first_name} ${user.last_name}`}</TableCell>
                                <TableCell>{user.email}</TableCell>
                                <TableCell>
                                    <a href={`${process.env.REACT_APP_BASE_URL}profile/${user._id}`} target="_blank">
                                        {`${process.env.REACT_APP_BASE_URL}profile/${user._id}`}
                                    </a>
                                </TableCell>
                                <TableCell>{moment(user.created_on).format("DD/MM/YYYY")}</TableCell>
                            </TableRow>)
                        )}
                        {users.length === 0 && (
                            <TableRow>
                                <TableCell>No users found!</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>)
                :
                (<List>
                    {users.map(bill => (
                        <ListItem key={bill.id}>
                            <ListItemText
                                primary={""}
                                secondary={bill.notes}
                            />
                        </ListItem>
                    ))}
                </List>)}
            {users.length > 0 && (
                <Pagination
                    pageCount={paginate.count/paginate.limit}
                    onPageChange={handleChangePage}
                />
            )}
            <UserLicensesModal
                open={openModal}
                onClose={() => {
                    setOpenModal(false)
                    setSelectedUser({})
                }}
                user={selectedUser}
            />
        </>
    )
    return returnObject
}
const mapStateToProps = (state) => ({
    paginate: state.users.paginate,
});
const mapDispatchToProps = {
    updateUser,
    fetchUsers
};
export default connect(mapStateToProps, mapDispatchToProps)(UserTable);