import React, { useEffect } from "react";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { useState } from "react";
import { Button } from "@material-ui/core";
import { CreateNewFolder, CloudDownload } from '@material-ui/icons';
import JSZip from "jszip";
import JSZipUtils from "jszip-utils";
import { saveAs } from "file-saver";
import html2canvas from "html2canvas";

//redux
import { connect } from "react-redux";
import { fetchQRCodes } from "../../../stores/qrCodes/actions";

//components
import QRTable from "./components/QRTable";
import QRCreateModal from "./components/QRCreateModal";
import QRUpdateModal from "./components/QRUpdateModal";

function QRCodes(props) {
  const [openModal, setOpenModal] = useState(false)
  const [updateModal, setUpdateModal] = useState(false)
  const [selectedRows, setSelectedRows] = useState([])
  const [downloading, setDownloading] = useState(false)
  const [selectedRow, setSelectedRow] = useState(null)

  useEffect(() => {
    const { limit, offset } = props.paginate
    props.fetchQRCodes({ limit, offset: 0 })
  }, [])
  const getQRLinks = async () => {
    let links = []
    for (let i = 0; i < selectedRows.length; i++) {
      const container = document.getElementById(`qr-gen-${selectedRows[i]._id}`);
      const canvas = await html2canvas(container, { backgroundColor: "rgba(0,0,0,0)", scale: 4 })
      links.push({
        filename: `${selectedRows[i]._id}.png`,
        link: canvas.toDataURL()
      })
    }
    return links
  }
  async function exportQRCodes() {
    // var table = document.getElementById("qr-table");
    // var html = table.outerHTML;
    // var url = 'data:application/vnd.ms-excel,' + escape(html); // Set your html table into url 
    // const elem = document.createElement("a")
    // elem.setAttribute("href", url);
    // elem.setAttribute("download", "export.xls"); // Choose the file name
    // elem.click()
    // return false;
    try {
      setDownloading(true)
      const links = await getQRLinks()
      const zip = new JSZip();
      let count = 0;
      const zipFilename = "MiitapQR.zip";
      links.forEach(function (item, i) {
        const filename = item.filename;
        // filename = filename.replace(/[\/\*\|\:\<\>\?\"\\]/gi, '').replace("httpsi.imgur.com","");
        // loading a file and add it in a zip file
        JSZipUtils.getBinaryContent(item.link, function (err, data) {
          if (err) {
            throw err; // or handle the error
          }
          zip.file(filename, data, { binary: true });
          count++;
          if (count == links.length) {
            zip.generateAsync({ type: 'blob' }).then(function (content) {
              saveAs(content, zipFilename);
            });
          }
        });
      });
    } catch (err) {

    } finally {
      setDownloading(false)
    }
  }
  const handleClickEdit = (row) => {
    setUpdateModal(true)
    setSelectedRow(row)
  }
  return (
    <>
      <PageTitle title="QR Codes" />
      <div className="d-flex justify-content-between mb-4">
        <Button
          color="primary"
          startIcon={<CreateNewFolder />}
          variant="contained"
          onClick={() => setOpenModal(true)}
        >
          Create
        </Button>
        <Button
          color="primary"
          startIcon={<CloudDownload />}
          variant="contained"
          onClick={exportQRCodes}
          disabled={downloading}
        >
          {downloading ? "Processing" : "Bulk Download"}
        </Button>
      </div>
      <QRTable
        qrCodes={props.qrCodes}
        onSelectRows={setSelectedRows}
        onClickEdit={handleClickEdit}
      />
      <QRCreateModal
        open={openModal}
        onClose={() => setOpenModal(false)}
      />
      <QRUpdateModal
        open={updateModal}
        onClose={() => setUpdateModal(false)}
        selectedRow={selectedRow}
      />
    </>
  )
}
const mapStateToProps = (state) => ({
  qrCodes: state.qrCodes.qrCodes,
  paginate: state.qrCodes.paginate,
});
const mapDispatchToProps = {
  fetchQRCodes,
};
export default connect(mapStateToProps, mapDispatchToProps)(QRCodes);