import React, { useEffect } from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import "./App.css"
// components
import LayoutAdmin from "./LayoutAdmin/LayoutAdmin";

// pages
import Error from "../pages/error";
import Login from "../pages/login";
import ResetPassword from "../pages/ResetPassword";

// context
import { useUserState } from "../context/UserContext";

//redux
import { connect } from "react-redux";
import { fetchProfile } from "../stores/profile/actions";

function App({ token, fetchProfile, profile }) {
  useEffect(() => {
    if (token) {
      fetchProfile();
    }
  }, [token])
  return (
    <HashRouter>
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/login" />} />
        <Route
          exact
          path="/admin"
          render={() => <Redirect to="/admin/users" />}
        />
        <PrivateRoute path="/admin" component={LayoutAdmin} />
        <PublicRoute path="/login" component={Login} />
        <PublicRoute path="/resetPassword/:token" component={ResetPassword} />
        <Route component={Error} />
      </Switch>
    </HashRouter>
  );

  // #######################################################################

  function PrivateRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
          token ? (
            React.createElement(component, props)
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  from: props.location,
                },
              }}
            />
          )
        }
      />
    );
  }

  function PublicRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
          token ? (
            <Redirect
              to={{
                pathname: profile.role === "admin" ? "/admin/users" : "/app/dashboard",
              }}
            />
          ) : (
            React.createElement(component, props)
          )
        }
      />
    );
  }
}
const mapStateToProps = (state) => ({
  token: state.auth.token,
  profile: state.auth.profile,
});
const mapDispatchToProps = {
  fetchProfile
};
export default connect(mapStateToProps, mapDispatchToProps)(App);