import { LICENSES_LOADING, LICENSES_SET_DATA } from "./constants";
import { _fetchLicenses, _changePassword, _createLicense, _updateLicense, _downloadTheme} from "./services";

export function licensesSetLoading(loading) {
	return {
		type: LICENSES_LOADING,
		payload: loading
	};
}
export function licensesSetData(params) {
	return {
		type: LICENSES_SET_DATA,
		payload: params
	};
}
export const fetchLicenses = (userId) => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		const {token} = getState().auth
		dispatch(licensesSetLoading(true));
		_fetchLicenses(userId, token).then((resp) => {
			if(Array.isArray(resp.licenses)){
				dispatch(licensesSetData(resp.licenses));
			}
			resolve(resp)
		}).catch(err => {
			reject(err);
		}).finally(() => {
			dispatch(licensesSetLoading(false));
		})
	})
};
export const updateLicense = (formData, id) => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		dispatch(licensesSetLoading(true));
		const { token } = getState().auth;
		_updateLicense(formData, id, token).then((resp) => {
			dispatch(fetchLicenses())
			resolve(resp)
		}).catch(err => {
			reject(err);
		}).finally(() => {
			dispatch(licensesSetLoading(false));
		})
	})
};
export const createLicense = (formData) => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		const {token} = getState().auth
		dispatch(licensesSetLoading(true));
		_createLicense(formData, token).then((resp) => {
			resolve(resp)
		}).catch(err => {
			reject(err);
		}).finally(() => {
			dispatch(licensesSetLoading(false));
		})
	})
};
export const downloadTheme = (website) => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		const {token} = getState().auth
		_downloadTheme(website, token).then((resp) => {
			resolve(resp)
		}).catch(err => {
			reject(err);
		})
	})
};