import { Button, Grid } from '@material-ui/core'
import { Check, Edit } from '@material-ui/icons'
import { useEffect, useState } from 'react'
import PageTitle from '../../../components/PageTitle'
import EditableText from '../../profile/components/EditableText'
import Swal from "sweetalert2"

//redux
import { connect } from "react-redux";
import { editProfile, changePassword } from "../../../stores/profile/actions";

function AdminProfile(props) {
    const [user, setUser] = useState({})
    const [isFetching, setIsFetching] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [isEditingPassword, setIsEditingPassword] = useState(false)
    const [passwordData, setPasswordData] = useState({
        oldPassword: "",
        password: "",
        confPassword: ""
    })

    useEffect(() => {
        if (props.profile?._id) {
            setUser(props.profile)
            setIsFetching(false)
        }
    }, [props.profile])

    const onStopEditing = async () => {
        setIsSubmitting(true)
        try {
            const formData = {
                email: user.email,
                name: user.name
            }
            await props.editProfile(formData, user._id)
            Swal.fire("Profile updated successfully!", "", "success")
            setPasswordData({
                oldPassword: "",
                password: "",
                confPassword: ""
            })
        } catch (e) {
            Swal.fire("Something went wrong", "", "error")
            console.error(e)
        } finally {
            setIsSubmitting(false)
            setIsEditing(false)
        }
    }
    const handleUpdatePassword = async () => {
        try {
            await props.changePassword(passwordData)
            Swal.fire("Password updated successfully!", "", "success")
        } catch (e) {
            let message = "Something went wrong"
            if (e?.response?.data?.message) {
                message = e?.response?.data?.message
            }
            Swal.fire(message, "", "error")
        } finally {
            setIsEditingPassword(false)
        }
    }

    return (
        <>
            <PageTitle title="Profile" />
            <div style={{
                marginBottom: '2em'
            }}>
                {!isEditing ?
                    <Button color="primary" startIcon={<Edit />} variant="contained" onClick={() => { setIsEditing(true) }}>Edit Profile</Button> :
                    <Button color="primary" startIcon={<Check />} variant="contained" onClick={() => { onStopEditing() }} disabled={isSubmitting}>
                        Done Editing
                    </Button>
                }
            </div>
            <Grid container spacing={1}>
                <Grid xs={12} sm={6} item>
                    <EditableText
                        label="Full Name"
                        value={`${user.first_name} ${user.last_name}`}
                        isEditing={isEditing}
                        onChange={(value) => setUser({ ...user, name: value })}
                        type="text"
                    />
                </Grid>
                <Grid xs={12} sm={6} item>
                    <EditableText
                        label="E-mail"
                        value={user.email}
                        isEditing={isEditing}
                        onChange={(value) => setUser({ ...user, email: value })}
                        type="text"
                    />
                </Grid>
            </Grid>
            <div style={{
                marginTop: '2em'
            }}>
                {!isEditingPassword ?
                    <Button color="primary" startIcon={<Edit />} variant="contained" onClick={() => { setIsEditingPassword(true) }}>Update Password</Button> :
                    <Button color="primary" startIcon={<Check />} variant="contained" onClick={() => { handleUpdatePassword() }} disabled={isSubmitting}>
                        Done Updating
                    </Button>
                }
                {isEditingPassword && (
                    <Grid container spacing={1} style={{ marginTop: "2rem" }}>
                        <Grid xs={12} sm={4} item>
                            <EditableText
                                label="Old Password"
                                value={passwordData.oldPassword}
                                isEditing={true}
                                onChange={(value) => setPasswordData({ ...passwordData, oldPassword: value })}
                                type="password"
                            />
                        </Grid>
                        <Grid xs={12} sm={4} item>
                            <EditableText
                                label="New Password"
                                value={passwordData.password}
                                isEditing={true}
                                onChange={(value) => setPasswordData({ ...passwordData, password: value })}
                                type="password"
                            />
                        </Grid>
                        <Grid xs={12} sm={4} item>
                            <EditableText
                                label="Confirm Password"
                                value={passwordData.confPassword}
                                isEditing={true}
                                onChange={(value) => setPasswordData({ ...passwordData, confPassword: value })}
                                type="password"
                            />
                        </Grid>
                    </Grid>
                )}
            </div>
        </>
    )
}
const mapStateToProps = (state) => ({
    profile: state.profile.profile
});
const mapDispatchToProps = {
    editProfile,
    changePassword
};
export default connect(mapStateToProps, mapDispatchToProps)(AdminProfile);