import { USERS_LOADING, USERS_SET_DATA, USERS_SET_PAGINATE } from "./constants";
const initialState = {
  loading: false,
  users: [],
  paginate: {
    count: 0,
    limit: 20,
    offset: 0
  }
};
export function usersReducer(state = initialState, action) {
  switch (action.type) {
    case USERS_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case USERS_SET_DATA:
      return {
        ...state,
        users: action.payload,
      };
    case USERS_SET_PAGINATE:
      return {
        ...state,
        paginate: action.payload,
      };
    default:
      return state;
  }
}
