import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import Swal from "sweetalert2";

//hooks
import { useDebounce } from "../../../../hooks/useDebounce";

//redux
import { connect, useSelector } from "react-redux";
import { assignQRCode } from "../../../../stores/qrCodes/actions";
import { _fetchUsers } from "../../../../stores/users/services";

function QRUpdateModal({
  open,
  onClose = () => {},
  assignQRCode,
  selectedRow,
}) {
  const {token} = useSelector((state) => state.auth)
  const [searchText, setSearchText] = useState("");
  const [btnLoading, setBtnLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null)
  const debouncedValue = useDebounce(searchText, 1000);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setBtnLoading(true);
      await assignQRCode({
        profileId: selectedUser?._id,

      }, selectedRow?._id);
      Swal.fire(`QR links updated successfully`, "", "success");
      onClose();
    } catch (e) {
      Swal.fire("Something went wrong", "", "error");
      console.error(e);
    } finally {
      setBtnLoading(false);
    }
  };
  useEffect(() => {
    (async () => {
      if (debouncedValue.length >= 3) {
        const data = await _fetchUsers({
          email: debouncedValue,
        }, token);
        if(Array.isArray(data?.users)){
            setUsers(data?.users)
        }
      } else {
        setUsers([]);
      }
    })();
  }, [debouncedValue]);
  return (
    <Dialog open={open} onClose={onClose} maxWidth={"sm"} fullWidth={true}>
      <DialogTitle>Update Dynamic QR</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          {/* <TextField
            label={"Type Email ID"}
            type={"text"}
            style={{ width: "100%" }}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          /> */}
          <Autocomplete
            options={users}
            getOptionLabel={(option) => `${option.first_name} ${option.last_name} (${option.email})`}
            style={{ width: "100%" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Enter Email ID"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
            )}
            onChange={(e, v) => setSelectedUser(v)}
          />
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={onClose}>
            Cancel
          </Button>
          <Button 
            color="primary" 
            type="submit" 
            disabled={btnLoading || !selectedUser}
        >
            Update
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
  assignQRCode,
};
export default connect(mapStateToProps, mapDispatchToProps)(QRUpdateModal);
