import axios from "axios";
export function _fetchUsers(params, token) {
  return new Promise((resolve, reject) => {
    let query = new URLSearchParams(params).toString();
    axios
      .get(`${process.env.REACT_APP_API_URL}v2/users?${query}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function _updateUser(formData, id, token) {
  return new Promise((resolve, reject) => {
    axios
      .put(`${process.env.REACT_APP_API_URL}users/${id}`, formData, {
        headers:{
          Authorization: `Bearer ${token}`
        }
      })
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}