import { QR_CODE_LOADING, QR_CODE_SET_DATA, QR_CODE_SET_PAGINATE } from "./constants";
import { _fetchQRCodes, _updateQRCode, _createQRCode, _assignQRCode } from "./services";

export function qrSetLoading(loading) {
	return {
		type: QR_CODE_LOADING,
		payload: loading
	};
}
export function qrSetData(params) {
	return {
		type: QR_CODE_SET_DATA,
		payload: params
	};
}
export function qrSetPaginate(params) {
	return {
		type: QR_CODE_SET_PAGINATE,
		payload: params
	};
}
export const fetchQRCodes = (params) => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		const {token} = getState().auth
		const { paginate } = getState().qrCodes;
		dispatch(qrSetLoading(true));
		_fetchQRCodes(params, token).then((resp) => {
			if(Array.isArray(resp.qrCodes)){
				dispatch(qrSetData(resp.qrCodes));
				dispatch(qrSetPaginate({
					...paginate,
					count: resp.count
				}));
			}
			resolve(resp)
		}).catch(err => {
			reject(err);
		}).finally(() => {
			dispatch(qrSetLoading(false));
		})
	})
};
export const updateQRCode = (formData, id) => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		dispatch(qrSetLoading(true));
		const { token } = getState().auth;
		const { paginate } = getState().qrCodes;
		_updateQRCode(formData, id, token).then((resp) => {
			dispatch(fetchQRCodes({limit: paginate.limit, offset: paginate.offset}))
			resolve(resp)
		}).catch(err => {
			reject(err);
		}).finally(() => {
			dispatch(qrSetLoading(false));
		})
	})
};
export const assignQRCode = (formData, id) => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		dispatch(qrSetLoading(true));
		const { token } = getState().auth;
		const { paginate } = getState().qrCodes;
		_assignQRCode(formData, id, token).then((resp) => {
			dispatch(fetchQRCodes({limit: paginate.limit, offset: paginate.offset}))
			resolve(resp)
		}).catch(err => {
			reject(err);
		}).finally(() => {
			dispatch(qrSetLoading(false));
		})
	})
};
export const createQRCode = (formData) => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		dispatch(qrSetLoading(true));
		const { token } = getState().auth;
		const { paginate } = getState().qrCodes;
		_createQRCode(formData, token).then((resp) => {
			dispatch(fetchQRCodes({limit: paginate.limit, offset: paginate.offset}))
			resolve(resp)
		}).catch(err => {
			reject(err);
		}).finally(() => {
			dispatch(qrSetLoading(false));
		})
	})
};