import { USERS_LOADING, USERS_SET_DATA, USERS_SET_PAGINATE } from "./constants";
import { _fetchUsers, _updateUser } from "./services";

export function usersSetLoading(loading) {
	return {
		type: USERS_LOADING,
		payload: loading
	};
}
export function usersSetData(params) {
	return {
		type: USERS_SET_DATA,
		payload: params
	};
}
export function usersSetPaginate(params) {
	return {
		type: USERS_SET_PAGINATE,
		payload: params
	};
}
export const fetchUsers = (params) => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		const {token} = getState().auth
		const {paginate} = getState().users
		dispatch(usersSetLoading(true));
		_fetchUsers(params, token).then((resp) => {
			if(Array.isArray(resp.users)){
				dispatch(usersSetData(resp.users));
				dispatch(usersSetPaginate({
					count: resp.count, 
					limit: params.limit, 
					offset: params.offset + params.limit
				}));
			}
			resolve(resp)
		}).catch(err => {
			reject(err);
		}).finally(() => {
			dispatch(usersSetLoading(false));
		})
	})
};
export const updateUser = (formData, id) => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		dispatch(usersSetLoading(true));
		const { token } = getState().auth;
		_updateUser(formData, id, token).then((resp) => {
			dispatch(fetchUsers())
			resolve(resp)
		}).catch(err => {
			reject(err);
		}).finally(() => {
			dispatch(usersSetLoading(false));
		})
	})
};