import React, { useState, useEffect } from "react";
import {
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  useMediaQuery,
  useTheme,
  Tooltip,
  Switch,
  Button,
  Paper,
} from "@material-ui/core";
import { Edit, CloudDownload } from "@material-ui/icons";
import Swal from "sweetalert2";
import moment from "moment";
import Pagination from "../../../../components/Pagination/Pagination";
import { QRCodeSVG } from "qrcode.react";
import html2canvas from "html2canvas";

//redux
import { connect } from "react-redux";
import {
  updateQRCode,
  fetchQRCodes,
  qrSetPaginate,
} from "../../../../stores/qrCodes/actions";

function QRTable({
  qrCodes,
  updateQRCode,
  paginate,
  qrSetPaginate,
  fetchQRCodes,
  onSelectRows,
  onClickEdit
}) {
  const useStyles = makeStyles(() => ({
    root: {
      tableHead: {
        fontWeight: 700,
      },
    },
  }));
  const [selectedRows, setSelectedRows] = useState([]);
  const [isCheckedAll, setCheckedAll] = useState(false);
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.up("sm"));
  const onUpdateQRCode = async (id, checked) => {
    Swal.fire({
      title: "Are you sure want to change the QR status?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      icon: "warning",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await updateQRCode({ ids: [id], printed: checked });
      }
    });
  };
  const handleChangePage = async (event) => {
    const page = event.selected;
    let params = {
      ...paginate,
      offset: paginate.limit * page,
    };
    await fetchQRCodes(params);
    qrSetPaginate(params);
  };
  const downloadQRCode = (id) => {
    // Generate download with use canvas and stream
    const container = document.getElementById(`qr-gen-${id}`);
    html2canvas(container, { backgroundColor: "rgba(0,0,0,0)", scale: 4 }).then(
      function (canvas) {
        var link = document.createElement("a");
        document.body.appendChild(link);
        link.download = `${id}.png`;
        link.href = canvas.toDataURL();
        link.target = "_blank";
        link.click();
      },
    );
  };
  const handleSelectRow = (item, checked) => {
    if (checked) {
      setSelectedRows((session) => {
        let sessionArr = [...session];
        let filteredSessions = session.filter((prp) => prp._id === item._id);
        if (!filteredSessions.length) {
          sessionArr.push(item);
        }
        return sessionArr;
      });
    } else {
      setSelectedRows((session) => {
        let filteredProperties = session.filter(
          (prop) => prop._id !== item._id,
        );
        return filteredProperties;
      });
    }
  };
  const handleSelectAll = (checked) => {
    if (checked) {
      setSelectedRows(qrCodes);
    } else {
      setSelectedRows([]);
    }
  };
  useEffect(() => {
    handleSelectAll(isCheckedAll);
  }, [qrCodes]);
  useEffect(() => {
    onSelectRows(selectedRows);
  }, [selectedRows]);
  const classes = useStyles();
  const returnObject = (
    <>
      {match ? (
        <TableContainer component={Paper}>
          <Table id="qr-table">
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell>
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      handleSelectAll(e.target.checked);
                      setCheckedAll(e.target.checked);
                    }}
                  />
                </TableCell>
                <TableCell>ID</TableCell>
                <TableCell>Link</TableCell>
                <TableCell>QR Value</TableCell>
                <TableCell>QR</TableCell>
                <TableCell>Converted</TableCell>
                <TableCell>Created On</TableCell>
                <TableCell>Printed</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {qrCodes.map((qr, index) => (
                <TableRow key={qr._id}>
                  <TableCell>
                    <input
                      type="checkbox"
                      onChange={(e) => handleSelectRow(qr, e.target.checked)}
                      checked={
                        selectedRows.filter((sn) => sn._id === qr._id).length
                          ? true
                          : false
                      }
                    />
                  </TableCell>
                  <TableCell>{qr._id}</TableCell>
                  <TableCell>
                    <a href={`${qr.link}`} target="_blank">
                      {qr.link}
                    </a>
                  </TableCell>
                  <TableCell>
                    <a
                      href={`${process.env.REACT_APP_BASE_URL}qr/${qr._id}`}
                      target="_blank"
                    >
                      {`${process.env.REACT_APP_BASE_URL}qr/${qr._id}`}
                    </a>
                  </TableCell>
                  <TableCell>
                    <div
                      style={{
                        background: "#fff",
                        borderRadius: 10,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: 125,
                        width: 125,
                      }}
                      id={`qr-gen-${qr._id}`}
                    >
                      <QRCodeSVG
                        value={`${process.env.REACT_APP_BASE_URL}qr/${qr._id}`}
                        size={100}
                      />
                    </div>
                  </TableCell>
                  <TableCell>{qr.converted ? "Yes" : "No"}</TableCell>
                  <TableCell>
                    {moment(qr.created_on).format("DD/MM/YYYY")}
                  </TableCell>
                  <TableCell>
                    <Tooltip title="Update">
                      <span>
                        <Switch
                          checked={qr.printed}
                          onChange={(e) =>
                            onUpdateQRCode(qr._id, e.target.checked)
                          }
                        />
                      </span>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <div className="d-flex">
                      <Button
                        color="primary"
                        startIcon={<CloudDownload />}
                        variant="contained"
                        onClick={() => downloadQRCode(qr._id)}
						style={{width: 180}}
                      >
                        DOWNLOAD QR
                      </Button>
                      <Button
                        color="primary"
                        startIcon={<Edit />}
                        variant="contained"
                        onClick={() => onClickEdit(qr)}
						style={{marginLeft: 10}}
                      >
                        Edit
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
              {qrCodes.length === 0 && (
                <TableRow>
                  <TableCell>No QR Codes found!</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <List>
          {qrCodes.map((bill) => (
            <ListItem key={bill.id}>
              <ListItemText primary={""} secondary={bill.notes} />
            </ListItem>
          ))}
        </List>
      )}
      {qrCodes.length > 0 && (
        <Pagination
          pageCount={paginate.count / paginate.limit}
          onPageChange={handleChangePage}
        />
      )}
    </>
  );
  return returnObject;
}
const mapStateToProps = (state) => ({
  paginate: state.qrCodes.paginate,
});
const mapDispatchToProps = {
  updateQRCode,
  fetchQRCodes,
  qrSetPaginate,
};
export default connect(mapStateToProps, mapDispatchToProps)(QRTable);