import { QR_CODE_LOADING, QR_CODE_SET_DATA, QR_CODE_SET_PAGINATE } from "./constants";
const initialState = {
  loading: false,
  qrCodes: [],
  paginate: {
    count: 0,
    limit: 20,
    offset: 0
  }
};
export function qrReducer(state = initialState, action) {
  switch (action.type) {
    case QR_CODE_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case QR_CODE_SET_DATA:
      return {
        ...state,
        qrCodes: action.payload,
      };
    case QR_CODE_SET_PAGINATE:
      return {
        ...state,
        paginate: action.payload,
      };
    default:
      return state;
  }
}
