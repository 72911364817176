import ReactPaginate from 'react-paginate';
const Pagination = (props) => {
    return (
        <ReactPaginate
            onPageChange={props.onPageChange}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={props.pageCount}
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
        />
    )
}
export default Pagination;