import React, {useEffect} from "react";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { useState } from "react";

//redux
import { connect } from "react-redux";
import { fetchUsers } from "../../../stores/users/actions";

//components
import UserTable from "./components/UserTable";

function Users (props) {
  useEffect(()=>{
    const {limit, offset} = props.paginate
    props.fetchUsers({limit, offset: 0})
  }, [])

  return (
    <>
      <PageTitle title="Users" />
      <UserTable users={props.users} />
    </>
  )
}
const mapStateToProps = (state) => ({
  users: state.users.users,
  paginate: state.users.paginate,
});
const mapDispatchToProps = {
  fetchUsers,
};
export default connect(mapStateToProps, mapDispatchToProps)(Users);