import React, { useState, useEffect } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField
} from "@material-ui/core";
import Swal from "sweetalert2"

//redux
import { connect } from "react-redux";
import {createQRCode} from "../../../../stores/qrCodes/actions";

function QRCreate({
    open,
    // onConfirm = () => {},
    onClose = () => { },
    createQRCode
}) {
    const [count, setCount] = useState("20")
    const [btnLoading, setBtnLoading] = useState(false)
    const handleSubmit = async(e) =>{
        try {
            e.preventDefault()
            setBtnLoading(true)
            await createQRCode({count})
            Swal.fire(`${count} QR links generated successfully`, "", "success")
            onClose()
        } catch (e) {
            Swal.fire("Something went wrong", "", "error")
            console.error(e)
        } finally {
            setBtnLoading(false)
        }
    }
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Create Dynamic QRs</DialogTitle>
            <form onSubmit={handleSubmit}>
            <DialogContent>
                <TextField
                    label={"No. of QR Codes"}
                    type={"number"}
                    style={{ width: "100%" }}
                    value={count}
                    onChange={(e) => setCount(e.target.value)}
                    required
                />
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={onClose}>Cancel</Button>
                <Button
                    color="primary"
                    type="submit"       
                    disabled={btnLoading}             
                >
                    Generate
                </Button>
            </DialogActions>
            </form>
        </Dialog>
    )
}
const mapStateToProps = (state) => ({
});
const mapDispatchToProps = {
    createQRCode
};
export default connect(mapStateToProps, mapDispatchToProps)(QRCreate);