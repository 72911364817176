import { TextField, Typography } from "@material-ui/core";

export default function EditableText ({
  label,
  value="",
  isEditing=false,
  onChange,
  type
}) {
  return (
    <>
      {isEditing ? (
        <TextField 
          label={label} 
          value={value} 
          type={type}
          onChange={(e)=>onChange(e.target.value)}
        />
      ) : (
        <div>
          <Typography variant="overline">{label}</Typography>
          <Typography>{value}</Typography>
        </div>
      )}
    </>
  )
}