import React, { useState, useEffect } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField
} from "@material-ui/core";
import Swal from "sweetalert2";

//redux
import { connect } from "react-redux";
import {forgotPassword} from "../../../stores/auth/actions";

function ResetPasswordModal({
    open,
    // onConfirm = () => {},
    onClose = () => { },
    forgotPassword
}) {
    const [email, setEmail] = useState("")
    const [btnLoading, setBtnLoading] = useState(false)

    const handleSubmit = async(e) =>{
        try {
			e.preventDefault();
			setBtnLoading(true)
			await forgotPassword({email})
			Swal.fire("Success!", "Forget password link successfully sent to your email!", "success")
		} catch (err) {
            console.log(err)
			let message = "Invalid Email/Password";
			if (err?.response?.data?.message) {
				message = err?.response?.data?.message
			}
			Swal.fire("Error!", message, "error")
		} finally {
			setBtnLoading(false)
            onClose()
		}
    }
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Reset Your Password</DialogTitle>
            <form onSubmit={handleSubmit}>
            <DialogContent style={{ overflowX: "hidden" }}>
                <TextField onInput={e => { setEmail(e.target.value) }} style={{ width: '100%', margin: '4px' }} variant="standard" type="email" label="Enter Email" value={email} required />
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={onClose}>Cancel</Button>
                <Button
                    color="primary"
                    onClick={() => { }}
                    type="submit"
                    disabled={btnLoading}
                >
                    Submit
                </Button>
            </DialogActions>
            </form>
        </Dialog>
    )
}
const mapStateToProps = (state) => ({
});
const mapDispatchToProps = {
    forgotPassword
};
export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordModal);